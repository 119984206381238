import React from 'react';
import { Routes, Route } from "react-router-dom";
import Home from '../Content/Home';
import InvalidPath from '../Content/InvalidPath';
import ScratchAndWin from '../Content/ScratchAndWin';


const MainNavigator = () => {
    return (
        <Routes>
            <Route path="*" element={<InvalidPath />}></Route>
            {/* <Route path="/" element={<Home />}></Route> */}
            <Route path=":key" element={<Home />}></Route>
        </Routes>
    )
}

export default MainNavigator